import {
  addDays,
  endOfMonth,
  endOfYear,
  setMonth,
  setYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { parse, stringifyUrl } from "query-string";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { formatDateToMMddyyyySeparatorSlash } from "../../../../../helpers";
import { history } from "../../../../../historyInstance";

export const setParams = (newParams = {}) => {
  const prevQuery = parse(location.search);

  const strQuery = stringifyUrl({
    url: window.location.pathname,
    query: { ...prevQuery, ...newParams },
  });

  history.push(strQuery, window.history.state?.state);
};

export const makeDates = (params) => {
  if (!params?.dateType) {
    return {};
  }

  if (params?.dateType === "None") {
    return { from: null, to: null };
  }

  const now = new Date();

  let from;
  let to;

  if (params?.dateType === "Q4 (2021)") {
    const f = startOfMonth(setMonth(setYear(now, 2021), 9));
    const t = endOfMonth(setMonth(setYear(now, 2021), 11));
    from = f;
    to = t;
  } else if (params?.dateType === "Q3 (2021)") {
    const f = startOfMonth(setMonth(setYear(now, 2021), 6));
    const t = endOfMonth(setMonth(setYear(now, 2021), 8));
    from = f;
    to = t;
  } else if (params?.dateType === "Q2 (2021)") {
    const f = startOfMonth(setMonth(setYear(now, 2021), 3));
    const t = endOfMonth(setMonth(setYear(now, 2021), 5));
    from = f;
    to = t;
  } else if (params?.dateType === "Q1 (2021)") {
    const f = startOfMonth(setMonth(setYear(now, 2021), 0));
    const t = endOfMonth(setMonth(setYear(now, 2021), 2));
    from = f;
    to = t;
  } else if (params?.dateType === "Q4 (2020)") {
    const f = startOfMonth(setMonth(setYear(now, 2020), 9));
    const t = endOfMonth(setMonth(setYear(now, 2020), 11));
    from = f;
    to = t;
  } else if (params?.dateType === "Q3 (2020)") {
    const f = startOfMonth(setMonth(setYear(now, 2020), 6));
    const t = endOfMonth(setMonth(setYear(now, 2020), 8));
    from = f;
    to = t;
  } else if (params?.dateType === "Q2 (2020)") {
    const f = startOfMonth(setMonth(setYear(now, 2020), 3));
    const t = endOfMonth(setMonth(setYear(now, 2020), 5));
    from = f;
    to = t;
  } else if (params?.dateType === "Q1 (2020)") {
    const f = startOfMonth(setMonth(setYear(now, 2020), 0));
    const t = endOfMonth(setMonth(setYear(now, 2020), 2));
    from = f;
    to = t;
  } else if (params?.dateType === "Q2 (2022)") {
    const f = startOfMonth(setMonth(setYear(now, 2022), 3));
    const t = endOfMonth(setMonth(setYear(now, 2022), 5));
    from = f;
    to = t;
  } else if (params?.dateType === "Q1 (2022)") {
    const f = startOfMonth(setMonth(setYear(now, 2022), 0));
    const t = endOfMonth(setMonth(setYear(now, 2022), 2));
    from = f;
    to = t;
  } else if (params?.dateType === "None") {
    from = undefined;
    to = undefined;
  } else if (params?.dateType === "Today") {
    from = startOfDay(now);
  } else if (params?.dateType === "Yesterday") {
    from = startOfDay(subDays(now, 1));
    to = startOfDay(subDays(now, 1));
  } else if (params?.dateType === "Over the past two days") {
    from = startOfDay(subDays(now, 1));
  } else if (params?.dateType === "Last 7 Days") {
    from = startOfDay(subDays(now, 6));
  } else if (params?.dateType === "Last 15 Days") {
    from = startOfDay(subDays(now, 14));
  } else if (params?.dateType === "Last 30 Days") {
    from = startOfDay(subDays(now, 29));
  } else if (params?.dateType === "Last 60 Days") {
    from = startOfDay(subDays(now, 59));
  } else if (params?.dateType === "Last 90 Days") {
    from = startOfDay(subDays(now, 89));
  } else if (params?.dateType === "This Month") {
    from = startOfDay(startOfMonth(now));
    to = startOfDay(endOfMonth(now));
  } else if (params?.dateType === "Last Month") {
    const prevMonth = subMonths(now, 1);
    const startPrevMonth = startOfMonth(prevMonth);
    const endPrevMonth = endOfMonth(prevMonth);

    from = startOfDay(startPrevMonth);
    to = startOfDay(endPrevMonth);
  } else if (params?.dateType === "Last 365 Days") {
    from = startOfDay(subDays(now, 364));
  } else if (params?.dateType === "2020-Today") {
    from = startOfYear(new Date().setFullYear(2020));
  } else if (params?.dateType === "2023") {
    from = startOfYear(new Date().setFullYear(2023));
    to = startOfDay(endOfYear(new Date().setFullYear(2023)));
  } else if (params?.dateType === "2022") {
    from = startOfYear(new Date().setFullYear(2022));
    to = startOfDay(endOfYear(new Date().setFullYear(2022)));
  } else if (params?.dateType === "2021") {
    from = startOfYear(new Date().setFullYear(2021));
    to = startOfDay(endOfYear(new Date().setFullYear(2021)));
  } else if (params?.dateType === "2020") {
    from = startOfYear(new Date().setFullYear(2020));
    to = startOfDay(endOfYear(new Date().setFullYear(2020)));
  } else if (params?.dateType === "This Year") {
    const startThisYear = startOfYear(now);

    from = startOfDay(startThisYear);
    to = endOfYear(now);
  } else if (params?.dateType === "Earlier Than 7 Days Ago") {
    from = null;
    to = startOfDay(subDays(now, 7));
  } else if (params?.dateType === "Earlier Than 15 Days Ago") {
    from = null;
    to = startOfDay(subDays(now, 15));
  } else if (params?.dateType === "Earlier Than 30 Days Ago") {
    from = null;
    to = startOfDay(subDays(now, 30));
  } else if (params?.dateType === "Earlier Than 60 Days Ago") {
    from = null;
    to = startOfDay(subDays(now, 60));
  } else if (params?.dateType === "Earlier Than 90 Days Ago") {
    from = null;
    to = startOfDay(subDays(now, 90));
  } else if (params?.dateType === "Next 7 Days") {
    from = null;
    to = startOfDay(addDays(now, 7));
  } else if (params?.dateType === "Next 15 Days") {
    from = null;
    to = startOfDay(addDays(now, 15));
  } else if (params?.dateType === "Next 30 Days") {
    from = null;
    to = startOfDay(addDays(now, 30));
  } else if (params?.dateType === "Next 60 Days") {
    from = null;
    to = startOfDay(addDays(now, 60));
  } else if (params?.dateType === "Next 90 Days") {
    from = null;
    to = startOfDay(addDays(now, 90));
  } else if (params?.dateType === "Custom Range") {
    if (params.from) {
      from = params.from;
    }

    if (params.to) {
      to = params.to;
    }
  }

  return {
    from: formatDateToMMddyyyySeparatorSlash(from),
    to: to ? formatDateToMMddyyyySeparatorSlash(to) : undefined,
  };
};

export const useFilter = (fetch) => {
  const location = useLocation();

  const refDefaultUrl = useRef<string>("");

  const match = useRouteMatch();
  const [matchRoute, setMatchRoute] = useState(false);

  useEffect(() => {
    refDefaultUrl.current = window.location.pathname;
  }, []);

  const prevPath = useRef<string>();

  useEffect(() => {
    /* Извините меня за это, но нам надо понимать когда предыдущий роут равен текущему роуту */

    if (match.path && !prevPath.current) {
      prevPath.current = match.path;
    } else if (
      match.path &&
      prevPath.current &&
      match.path === prevPath.current
    ) {
      setMatchRoute(true);
    }
  }, [match.path, match.url]);

  const fetcher = useCallback(
    async (search) => {
      try {
        const query = parse(search);

        const dates = makeDates(query);

        if (query?.dateType) {
          delete query.dateType;
        }

        if (fetch) {
          await fetch({ ...query, ...dates });
        }
      } catch (e) {
        // logic
      }
    },
    [fetch]
  );

  const init = useRef<boolean>(false);

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      fetcher(location.search);
    }
  }, [fetcher, location.search]);

  const initHistory = useRef<boolean>(false);

  const call = useCallback(() => {
    if (matchRoute || refDefaultUrl.current === history.location.pathname) {
      fetcher(history.location.search);
    }
  }, [fetcher, matchRoute]);

  useEffect(() => {
    let unlisten;

    if (!initHistory.current && init.current) {
      initHistory.current = true;
      unlisten = history.listen(call);
    }

    return () => {
      if (unlisten) {
        initHistory.current = false;
        unlisten();
      }
    };
  }, [call, fetcher, match.path]);

  return null;
};
