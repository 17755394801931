import { view } from "@risingstack/react-easy-state";
import { clsx } from "clsx";
import { useAtom } from "jotai";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { get } from "../../../../../../store/api";
import auth from "../../../../../../store/modules/auth";
import ui from "../../../../../../store/modules/ui";
import { makeDates } from "../../../AllClients/hooks/useFilter";
import CurrencyCell from "../components/CurrencyCell";
import FeatureBranchesFilter from "../components/FeatureBranchesFilter";
import FeatureDateFilter from "../components/FeatureDateFilter";
import FeatureIgnoreZeroValue from "../components/FeatureIgnoreZeroValue";
import FeatureLessThan from "../components/FeatureLessThan";
import FeatureMoreThan from "../components/FeatureMoreThan";
import FeatureRadioDataType from "../components/FeatureRadioDataType";
import FeatureSearchFilter from "../components/FeatureSearchFilter";
import FeatureTotalCurrency from "../components/FeatureTotalCurrency";
import SimpleTable from "../components/SimpleTable";
import TotalCell from "../components/TotalCell";
import { useAvailableCurrencies } from "../hooks/useAvailableCurrencies";
import { feeFiltersAtom } from "./atoms/feeFilters";
import { feeParamsAtom } from "./atoms/feeParams";
import { useQueryPNLFee } from "./queries/useQueryPNLFee";

export default view(() => {
  const history = useHistory();
  const urlParams = useParams();
  const match = useRouteMatch<{ userId?: string }>();

  const [enabled, setEnabled] = useState(false);

  const [params, setParams] = useAtom(feeParamsAtom);

  const tableQuery = useQueryPNLFee(params, enabled);

  const [filters, setFilters] = useAtom(feeFiltersAtom);

  const availableCurrencies = useAvailableCurrencies(
    tableQuery?.data?.data,
    filters
  );

  const { dashboardPnlShowHidden } = ui;

  useEffect(() => {
    setParams((prevState) => {
      return {
        ...prevState,
        branchId: urlParams?.userId || match.params.userId,
        showHidden: dashboardPnlShowHidden,
      };
    });

    setEnabled(true);
  }, [
    setParams,
    dashboardPnlShowHidden,
    urlParams?.userId,
    match.params.userId,
  ]);

  const columns = useMemo(() => {
    return [
      {
        id: "feeName",
        label: "Fee Name",
        nowrap: false,
        render: (rowData) => {
          const isTotal = rowData?.feeName?.includes("Total");
          return (
            <a
              className={clsx(
                isTotal ? "font-bold" : "font-normal",
                "w-[10rem] flex"
              )}
              onClick={() => {
                const { from, to } = makeDates({ dateType: filters.dateType });

                history.push("/app/admin/reports/pnl/full-details", {
                  branchId: params?.branchId,
                  from: from,
                  to: to,
                  feeName: rowData?.feeName,
                  feeId: rowData?.feeId,
                });
              }}
            >
              {rowData?.feeName}
            </a>
          );
        },
      },
      ...availableCurrencies.map((c) => ({
        id:
          filters.dataType === "+/-"
            ? c.value
            : c.value + "-" + filters.dataType,
        label: c.value,
        nowrap: true,
        right: true,
        render: (rowData) => {
          const isTotal = rowData?.feeName?.includes("Total");
          return (
            <a
              className={isTotal ? "font-bold" : "font-normal"}
              onClick={() => {
                const { from, to } = makeDates({ dateType: filters.dateType });

                history.push("/app/admin/reports/pnl/full-details", {
                  currencyId: c.value,
                  branchId: params?.branchId,
                  feeName: rowData?.feeName,
                  from: from,
                  to: to,
                  feeId: rowData?.feeId,
                });
              }}
            >
              <CurrencyCell
                rowTotalKey="feeName"
                rowData={rowData}
                c={c}
                filters={filters}
                params={params}
              />
            </a>
          );
        },
      })),
      {
        id:
          filters.dataType === "+/-"
            ? "total"
            : "total" + "-" + filters.dataType,
        label: "Total",
        nowrap: true,
        right: true,
        className: "bg-gray-100",
        render: (rowData) => {
          return (
            <a
              onClick={() => {
                const { from, to } = makeDates({ dateType: filters.dateType });

                history.push("/app/admin/reports/pnl/full-details", {
                  branchId: params?.branchId,
                  from: from,
                  to: to,
                  feeName: rowData?.feeName,
                  feeId: rowData?.feeId,
                });
              }}
            >
              <TotalCell rowData={rowData} params={params} filters={filters} />
            </a>
          );
        },
      },
    ];
  }, [availableCurrencies, filters, params, history]);

  const downloadCsv = useCallback(async () => {
    const { data } = await get(
      `${import.meta.env.VITE_API}/v3/reports/pnl-by-fee`,
      params,
      "blob",
      {
        Accept: "text/csv",
      }
    );

    const link = document.createElement("a");
    link.href = URL.createObjectURL(data);

    link.download = "pnl_fee.csv";
    link.click();
  }, [params]);

  return (
    <div className="w-full">
      <div className="flex flex-wrap flex-row items-end w-full flex-1 mb-2">
        <FeatureSearchFilter params={params} setParams={setParams} />

        {!!auth.profile && auth.profile?.isAdmin && !urlParams?.userId && (
          <FeatureBranchesFilter params={params} setParams={setParams} />
        )}

        <FeatureDateFilter
          filters={filters}
          params={params}
          setParams={setParams}
          setFilters={setFilters}
        />

        {/*   {isDev && (
          <div className="mb-2">
            <CustomDateRange
              filters={filters}
              params={params}
              setParams={setParams}
              setFilters={setFilters}
            />
          </div>
        )} */}

        <FeatureRadioDataType
          filters={filters}
          setFilters={setFilters}
          setParams={setParams}
        />
        <FeatureTotalCurrency params={params} setParams={setParams} />
        <FeatureLessThan params={params} setParams={setParams} />
        <FeatureMoreThan params={params} setParams={setParams} />
        <FeatureIgnoreZeroValue params={params} setParams={setParams} />
      </div>

      <div className="flex justify-end mb-2">
        <a onClick={downloadCsv}>Download CSV</a>
      </div>

      <SimpleTable
        columns={columns}
        queryData={tableQuery}
        setParams={setParams}
        params={params}
        rowClassName={(row) => {
          const isTotalRow = row?.feeName?.includes(`Total`);

          if (isTotalRow) {
            return "bg-gray-100";
          }
        }}
      />
    </div>
  );
});
