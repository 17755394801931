export const subTypeTypes = [
  {
    label: "Migom Visa®",
    value: "C1",
  },
  {
    label: "Migom Visa® Infinite",
    value: "C2",
  },
  {
    label: "Migom Visa® Elite Infinite",
    value: "C3",
  },
];

export const getTypeByUrl = (pathname) => {
  if (pathname?.includes("original")) {
    return subTypeTypes[0];
  }

  if (pathname?.includes("elite-infinite")) {
    return subTypeTypes[2];
  }

  if (pathname?.includes("infinite")) {
    return subTypeTypes[1];
  }
};

export const getBlockIdByType = (type) => {
  if (type === "C1") {
    return "migom-vis";
  }

  if (type === "C2") {
    return "migom-visa-infinit";
  }

  if (type === "C3") {
    return "migom-visa-elite-infinit";
  }
};
