import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { makeParams } from "../../helpers/makeParams";
import IStructure from "../../interfaces/structure";
import ITitle from "../../interfaces/title";
import { del, get, patch, post, put, showError } from "../api";
import auth from "./auth";

const setParentId = (
  node: { parentId: any; children: string | any[] | null; structureId: any },
  parentId: undefined
) => {
  if (parentId) {
    node.parentId = parentId;
  }
  if (node.children != null) {
    let result = null;
    for (let i = 0; result == null && i < node.children?.length; i++) {
      result = setParentId(node.children[i], node.structureId);
    }
    return result;
  }
  return null;
};

const defaultParams = {
  perPage: 20,
  pageIndex: 0,
  search: "",
  sortBy: "structureId",
  sortDirection: "asc",
  onlyPromoCodes: undefined,
  branchId: undefined,
};

interface IStore {
  loading?: boolean;
  items?: any[];
  payFee?: Record<string, any>;
  feeItems?: ITitle[];
  structures: IStructure[];
  structuresForCreateAccount?: any[];
  feeRules?: any[];
  tree?: IStructure;
  resolveStructures?: any[];
  structurePages?: number;
  defaultParams?: Record<string, any>;
  params?: Record<string, any>;

  [key: string]: any;
}

const fees = store<IStore>({
  loading: false,
  items: [],
  payFee: { fee: 0 },
  feeItems: [],
  structures: [],
  structuresForCreateAccount: [],
  feeRules: [],
  tree: undefined,
  resolveStructures: [],
  structurePages: 1,
  defaultParams: defaultParams,
  params: defaultParams,

  async fetchFeeTree() {
    try {
      const params: Record<string, any> = {};
      if (auth.profile && auth.profile?.isBranchAdmin) {
        params.branchId = auth.profile?.branch.parentUserId;
        params.rootStructureId = auth.profile?.branch.rootStructureId;
      }
      const { data } = await get(ENDPOINTS.fees.feeTree, params);
      setParentId(data, undefined);
      fees.tree = data;
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchStructures(customParams?: Record<string, any>) {
    try {
      fees.loading = true;
      const params: Record<string, any> = {
        page: 1,
        perPage: 99999,
        ...customParams,
      };

      if (auth.profile && auth.profile?.isBranchAdmin) {
        params.parentUserId = auth.profile?.branch?.parentUserId;
      }

      const { data } = await get(
        ENDPOINTS.fees.fetchStructuresWithPagination,
        params
      );

      fees.structures = data.data;
      fees.loading = false;
      return data.data;
    } catch (err) {
      showError(err);
      fees.loading = false;
      return false;
    }
  },
  async fetchStructuresForCreateAccount(customParams: Record<string, any>) {
    try {
      fees.loading = true;
      const params: Record<string, any> = {
        page: 1,
        perPage: 99999,
        ...customParams,
      };

      if (auth.profile && auth.profile?.isBranchAdmin) {
        params.parentUserId = auth.profile?.branch?.parentUserId;
      }

      const { data } = await get(
        ENDPOINTS.fees.fetchStructuresWithPagination,
        params
      );

      fees.structuresForCreateAccount = data.data;
      fees.loading = false;
      return data.data;
    } catch (err) {
      showError(err);
      fees.loading = false;
      return false;
    }
  },
  async fetchStructuresWithPagination(customParams: Record<string, any>) {
    try {
      fees.loading = true;
      const params = makeParams(fees, customParams);

      if (auth.profile && auth.profile?.isBranchAdmin) {
        params.parentUserId = auth.profile?.branch?.parentUserId;
      }

      const { data } = await get(
        ENDPOINTS.fees.fetchStructuresWithPagination,
        params
      );

      fees.structures = data.data;
      fees.structurePages = data.pages;
      fees.loading = false;
      return data;
    } catch (err) {
      showError(err);
      fees.loading = false;
      return false;
    }
  },
  async fetchPromoCodes() {
    try {
      const { data } = await get(ENDPOINTS.fees.fetchPromoCodes);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async addClientInStructure(
    structureId: string,
    payload: Record<string, any>
  ) {
    try {
      await post(
        ENDPOINTS.fees.addClientInStructure.replace(
          "{structureId}",
          structureId
        ),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async removeClientOutStructure(
    structureId: string,
    payload: Record<string, any>
  ) {
    try {
      await del(
        ENDPOINTS.fees.removeClientOutStructure.replace(
          "{structureId}",
          structureId
        ),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async validatePromoCode(promoCode: string) {
    try {
      const { data } = await get(ENDPOINTS.fees.validatePromoCode, {
        promoCode,
      });

      return data;
    } catch (err) {
      showError(err);
    }
  },
  async fetchFormulas(structureId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.fees.formulas.replace("{structureId}", structureId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async patchFormula(
    structureId: string,
    titleId: string,
    payload: Record<string, any>
  ) {
    try {
      await put(
        ENDPOINTS.fees.patchFormula
          .replace("{structureId}", structureId)
          .replace("{titleId}", titleId),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async deleteFormula(structureId: string, titleId: string) {
    try {
      await del(
        ENDPOINTS.fees.deleteFormula
          .replace("{structureId}", structureId)
          .replace("{titleId}", titleId)
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchFees() {
    try {
      const { data } = await get(ENDPOINTS.fees.fetchTitles);
      // console.log(data);
      fees.feeItems = data?.sort((a, b) =>
        a?.titleId?.localeCompare(b?.titleId)
      );
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async addStructure(payload: Record<string, any>) {
    try {
      await post(ENDPOINTS.fees.addStructure, payload);
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async addFormula(
    structureId: string,
    titleId: string,
    payload: Record<string, any>
  ) {
    try {
      await post(
        ENDPOINTS.fees.addFormula
          .replace("{structureId}", structureId)
          .replace("{titleId}", titleId),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async removeStructure(structureId: string, payload: Record<string, any>) {
    try {
      await del(
        ENDPOINTS.fees.removeStructure.replace("{structureId}", structureId),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async editStructure(structureId: string, payload: Record<string, any>) {
    try {
      await patch(
        ENDPOINTS.fees.editStructure.replace("{structureId}", structureId),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchTitles() {
    try {
      const { data } = await get(ENDPOINTS.fees.fetchTitles);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchStructure(structureId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.fees.fetchStructure.replace("{structureId}", structureId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchStructureWithChildren(structureId: string) {
    try {
      let structure = {};

      const responseStructure = await get(
        ENDPOINTS.fees.fetchStructure.replace("{structureId}", structureId)
      );

      const responseChildren = await get(
        ENDPOINTS.fees.fetchStructureChildren.replace(
          "{structureId}",
          structureId
        )
      );

      structure = {
        ...responseStructure.data,
        children: [...responseChildren.data],
      };

      return structure;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchAllFormulas(params) {
    try {
      const { data } = await get(ENDPOINTS.fees.fetchAllFormulas, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchResolveStructures(structureId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.fees.fetchResolveStructures.replace(
          "{structureId}",
          structureId
        )
      );
      fees.resolveStructures = data;
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default fees;
