import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import { useController, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
  checkIsCryptoCurrency,
  symbols,
} from "../../../helpers/formatToCurrencyString";

const MyAmount = ({
  autoFocus,
  control,
  controlCurrency,
  currencyName,
  name,
  rules,
  disabled,
  label,
  percentage,
  prefix = "",
  fixedDecimalScale,
  onFocus,
  decimal,
  hideBorder,
  showCurrencyInEnd,
  required,
  endAdornment,
  labelStyle,
  id,
}: any) => {
  const { field } = useController({
    name: prefix + name,
    control: control,
    rules: rules,
  });

  const currency = useWatch({
    name: prefix + (currencyName || "currency"),
    control: controlCurrency,
  });

  const isCrypto = checkIsCryptoCurrency(currency);

  return (
    <NumberFormat
      id={id}
      type="text"
      value={field.value}
      name={name}
      autoFocus={autoFocus}
      onChange={field.onChange}
      onBlur={field.onBlur}
      onFocus={onFocus}
      customInput={TextField}
      inputRef={field.ref}
      allowNegative={false}
      thousandSeparator
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimal !== undefined ? decimal : isCrypto ? 8 : 2}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <b className="text-lg">{percentage ? "%" : symbols[currency]}</b>
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {showCurrencyInEnd && (
              <InputAdornment position="start">
                <b className="text-lg">{currency}</b>
              </InputAdornment>
            )}

            {endAdornment && (
              <InputAdornment position="start">
                <b className="text-lg">{endAdornment}</b>
              </InputAdornment>
            )}
          </>
        ),
        disableUnderline: hideBorder,
      }}
      InputLabelProps={{
        shrink: true,
        style: labelStyle,
      }}
      fullWidth
      disabled={disabled}
      label={label}
      required={required}
      autoComplete="off"
    />
  );
};

export default MyAmount;
