import React, { useEffect, useRef, useState } from "react";
import { Alert } from "../../../../../../System";

const CryptoDeposit = ({ account, open, setOpen }) => {
  const timer = useRef<number>();

  const [copy, setCopy] = useState(false);
  const [openLearnMore, setOpenLearnMore] = useState(false);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const close = () => {
    setOpen(false);
  };

  const onCopy = () => {
    setCopy(true);

    navigator.clipboard.writeText(account?.number);

    timer.current = setTimeout(() => {
      setCopy(false);
      close();
    }, 1000);
  };

  const showLearnMore = () => {
    setOpenLearnMore(true);
  };

  const hideLearnMore = () => {
    setOpenLearnMore(false);
  };

  let messageCountConfirmation: JSX.Element | string = "";
  let messageWithUnderline: JSX.Element | string = "";
  let dangerous: JSX.Element | string = "";

  if (account?.currencyId === "BTC") {
    messageCountConfirmation = (
      <div>
        <div>
          <span>
            BTC deposits will credited to your account after 3 confirmations.
          </span>
          {openLearnMore ? (
            <div>
              <h3 className="m-0">
                <b className="m-3">
                  Why hasn&apos;t my transaction confirmed yet?
                </b>
              </h3>
              <p className="m-3">
                Every crypto transaction that&apos;s sent flows into what&apos;s
                called the mempool (short for memory pool) before it can be
                confirmed by miners. When there&apos;s a dramatic spike in
                transaction activity, the mempool can become congested because
                so many transactions are waiting to be included in the next
                block.
              </p>
              <p className="m-3">
                Crypto users across the network may notice their transactions
                sit as unconfirmed or pending for a longer period of time, and
                we understand this can cause users to be concerned about the
                status of their funds.
              </p>
              <p className="m-3">
                In most cases, your transactions will eventually confirm. It may
                just take longer than usual to do so.
              </p>
              <a onClick={hideLearnMore}> Hide</a>
            </div>
          ) : (
            <a onClick={showLearnMore}> Learn More.</a>
          )}
        </div>
      </div>
    );

    dangerous = (
      <span>
        We repeat: if you send any other coin other than BTC to this address, it
        will NOT come to the bank and will be lost on the network. For example,
        if you send, for example, ETH coin to this address, it will be LOST
        forever.
      </span>
    );

    messageWithUnderline = (
      <span>Please ensure you are only depositing BTC to this address.</span>
    );
  } else if (account?.currencyId === "ETH") {
    messageCountConfirmation = (
      <div>
        <div>
          <span className="m-3">
            ETH deposits will credited to your account after 12 confirmations.
          </span>
          {openLearnMore ? (
            <div>
              <h3 className="m-0">
                <b className="m-3">
                  Why hasn&apos;t my transaction confirmed yet?
                </b>
              </h3>
              <p className="m-3">
                Every crypto transaction that&apos;s sent flows into what&apos;s
                called the mempool (short for memory pool) before it can be
                confirmed by miners. When there&apos;s a dramatic spike in
                transaction activity, the mempool can become congested because
                so many transactions are waiting to be included in the next
                block.
              </p>
              <p className="m-3">
                Crypto users across the network may notice their transactions
                sit as unconfirmed or pending for a longer period of time, and
                we understand this can cause users to be concerned about the
                status of their funds.
              </p>
              <p className="m-3">
                In most cases, your transactions will eventually confirm. It may
                just take longer than usual to do so.
              </p>
              <a onClick={hideLearnMore}> Hide</a>
            </div>
          ) : (
            <a onClick={showLearnMore}> Learn More.</a>
          )}
        </div>
      </div>
    );

    dangerous = (
      <span>
        We repeat: if you send any other coin other than ETH to this address, it
        will NOT come to the bank and will be lost on the network. For example,
        if you send USDT to this address, it will be LOST forever.
      </span>
    );

    messageWithUnderline = (
      <span>Please ensure you are only depositing ETH to this address.</span>
    );
  } else if (account?.currencyId === "USDT") {
    messageCountConfirmation = (
      <div>
        <div>
          <span>
            USDT deposits will credited to your account after 12 confirmations.
          </span>
        </div>
      </div>
    );

    dangerous = (
      <span>
        We repeat: if you send any other coin, other than USDT, it will NOT come
        to the bank and will be lost on the network. For example, if you send,
        for example, ETH coin to this address, it will be LOST forever.
      </span>
    );

    messageWithUnderline = (
      <span>Please ensure you are only depositing USDT to this address.</span>
    );
  } else if (account?.currencyId === "USDC") {
    messageCountConfirmation = (
      <div>
        <div>
          <span>
            USDC deposits will credited to your account after 12 confirmations.
          </span>
        </div>
      </div>
    );

    dangerous = (
      <span>
        We repeat: if you send any other coin, other than USDC, it will NOT come
        to the bank and will be lost on the network. For example, if you send,
        for example, ETH coin to this address, it will be LOST forever.
      </span>
    );

    messageWithUnderline = (
      <span>Please ensure you are only depositing USDC to this address.</span>
    );
  } else if (account?.currencyId === "LTC") {
    messageCountConfirmation = (
      <div>
        <div>
          <span>
            LTC deposits will credited to your account after 3 confirmations.
          </span>
        </div>
      </div>
    );

    dangerous = (
      <span>
        We repeat: if you send any other coin other than LTC to this address, it
        will NOT come to the bank and will be lost on the network. For example,
        if you send any coin to this address, it will be LOST forever.
      </span>
    );

    messageWithUnderline = (
      <span>
        Please ensure you are only depositing LTC to this address. Depositing
        ANY other currency to this address will result in permanent loss.
      </span>
    );
  } else if (account?.currencyId === "MIGG") {
    messageCountConfirmation = (
      <div>
        <div>
          <span>
            MIGG deposits will credited to your account after 12 confirmations.
          </span>
        </div>
      </div>
    );

    dangerous = (
      <span>
        We repeat: if you send any other coin other than MIGG to this address,
        it will NOT come to the bank and will be lost on the network. For
        example, if you send any coin to this address, it will be LOST forever.
      </span>
    );

    messageWithUnderline = (
      <span>Please ensure you are only depositing MIGG to this address.</span>
    );
  }

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      content={
        <div>
          <h1 className="text-center text-2xl text-gray-600">
            <b>{account?.currencyId} Deposit</b>
          </h1>

          <div className="flex justify-center items-center">
            {/* <QRCode
              value={account?.number || ""}
              size={300}
              bgColor="#ffffff"
              fgColor="#000000"
              level="L"
              includeMargin={false}
              renderAs="svg"
              imageSettings={{
                src: "/images/design/logo/mini_logo.svg",
                width: 40,
                height: 45,
                excavate: true,
              }}
            /> */}

            <h2 className="text-2xl font-bold">
              Crypto deposits is not currently available
            </h2>
          </div>
          {/* <div className="m-3 flex justify-center">
            <b>
              {account?.number} <CopyAccountNumber number={account?.number} />
            </b>
          </div>

          {(account?.currencyId === "ETH" ||
            account?.currencyId === "USDT" ||
            account?.currencyId === "USDC" ||
            account?.currencyId === "MIGG") && (
            <p className="m-3 text-center text-base">
              Migom Bank is using ERC20 network. Please do not send coins from
              other networks, it might cause money loss.
            </p>
          )}

          <p className="m-3 text-center text-base">
            {messageCountConfirmation}
          </p>

          <p className="text-center text-base">
            <b className="underline">{messageWithUnderline}</b> Depositing ANY
            other currency to this address will result in permanent loss.
          </p>

          <div className="flex justify-center">
            <p className="text-center max-w-sm text-red-500 font-bold">
              {dangerous}
            </p>
          </div>

          <div className="flex justify-center">
            <button
              className="outline-none duration-300 border font-bold bg-main py-1.5 px-3 text-white rounded-2xl shadow"
              onClick={copy ? close : onCopy}
            >
              {copy
                ? "Copied deposit address to Clipboard, close"
                : "Copy deposit address to Clipboard"}
            </button>
          </div> */}
        </div>
      }
      size="sm"
    />
  );
};

export default CryptoDeposit;
