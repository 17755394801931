import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import { useController } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

export default ({ control, name, rules, label, disabled, required }: any) => {
  const controller = useController({
    name: name,
    control: control,
    rules: {
      required: required ? `Field '${label}' is required` : false,
      ...(rules || {}),
    },
  });

  return (
    <>
      <FormControlLabel
        labelPlacement="end"
        label={label}
        disabled={disabled}
        control={
          <Checkbox
            name={controller?.field?.name}
            inputRef={controller?.field?.ref}
            onChange={controller?.field?.onChange}
            onBlur={controller?.field?.onBlur}
            checked={controller?.field?.value || false}
            required={required}
          />
        }
      />

      <ErrorMessage controller={controller} />
    </>
  );
};
