import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import errors from "../../store/modules/errors";
import LazyImage from "../System/LazyImage";

const clearError = () => {
  errors.error = "";
};

const Error = view(() => {
  const { code, description, message, traceId, cfRay } = errors.error || {};

  const isTransactiveError = description
    ? description.toLowerCase().includes("transactive")
    : false;

  const isBadRequestFromAdmin = description
    ? description.toLowerCase().includes("operation_account_id_fkey")
    : false;

  const isAccessDenied = code === 403;

  const isBadRequest =
    (code >= 500 && code !== 4010 && code !== 4011 && code !== 4012) ||
    description?.includes("Network Error");

  if (isBadRequest) {
    return (
      <Dialog open={Boolean(errors.error)}>
        <DialogTitle>Error</DialogTitle>

        <DialogContent>
          <div className="text-base">
            <p className="mb-3">
              The request is being processed for a very long time.
            </p>

            <p className="mb-3">
              Please make sure you are connected to internet.
            </p>

            <p className="mb-3">
              If problem persist, please contact support at support@migom.com
              and do not repeat this operation again until the error has been
              clarified and fixed.
            </p>

            {code > -1 && <p className="mb-0">Error: {code}</p>}
          </div>

          {traceId && (
            <div className="flex justify-end">
              <span className="block text-gray-500">
                <span className="block">Trace ID: </span>
                <span className="block">{traceId}</span>
              </span>
            </div>
          )}

          {cfRay && (
            <div>
              <span>cf-ray: {cfRay}</span>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={clearError} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (isBadRequestFromAdmin) {
    return (
      <Dialog open={Boolean(errors.error)}>
        <DialogTitle>Bad request</DialogTitle>

        <DialogContent>
          <div>
            <p>
              <b className="text-red-500">
                You are deleting user who have accounts and these accounts have
                operations. We currently don&apos;t allow to delete users who
                already perform operations on accounts.
              </b>
            </p>

            <p>
              <b className="text-red-500">
                (Please note: accounts can be closed and invisible)
              </b>
            </p>

            <p>
              <b className="text-red-500">
                Please either delete these operations or contact tech department
                at <a href="mailto:dima@migom.com">dima@migom.com</a> and ask to
                delete this user
              </b>
            </p>
          </div>

          {cfRay && (
            <div>
              <span>cf-ray: {cfRay}</span>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={clearError} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (isTransactiveError) {
    return (
      <Dialog open={Boolean(errors.error)}>
        <DialogTitle>Error in Transactive</DialogTitle>

        <DialogContent>
          <div>
            <LazyImage src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/5a038f642c7d3a272c0d84fb/images/5b9a94132c7d3a03f89ebe02/logog_300x75.png" />

            <p>
              <b>
                Error in Transactive API have been detected. You need to stop
                all SEPA-related operations
              </b>
            </p>

            <p>
              <b>{description}</b>
            </p>

            <p>
              <b>
                You need to call Transactive Company immediately and ask them
                about this error.
              </b>
            </p>
            <p>
              <b>Continue performing SEPA operations can cause money loss</b>
            </p>

            <a
              rel="noreferrer"
              href="https://transactiveltd.com/#contactForm"
              target="_blank"
            >
              Transactive - Contact Us
            </a>
          </div>

          {cfRay && (
            <div>
              <span>cf-ray: {cfRay}</span>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={clearError} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (code == 0) {
    return (
      <Dialog open={Boolean(errors.error)}>
        <DialogTitle>{message}</DialogTitle>

        <DialogContent>
          {description && (
            <p dangerouslySetInnerHTML={{ __html: description }} />
          )}

          {cfRay && (
            <div>
              <span>cf-ray: {cfRay}</span>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={clearError} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (isAccessDenied) {
    return (
      <Dialog open={Boolean(errors.error)}>
        <DialogTitle>Access Denied</DialogTitle>

        <DialogActions>
          <Button onClick={clearError} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={Boolean(errors.error)}>
      <DialogTitle>{code && message ? message : "Error"}</DialogTitle>

      <DialogContent>
        <div>
          {description && (
            <p dangerouslySetInnerHTML={{ __html: description }} />
          )}
          {code > -1 && <p className="mb-0">Error: {code}</p>}
        </div>
        {traceId && (
          <div className="flex justify-end">
            <span className="block text-gray-500">
              <span className="block">Trace ID: </span>
              <span className="block">{traceId}</span>
            </span>
          </div>
        )}

        {cfRay && (
          <div>
            <span>cf-ray: {cfRay}</span>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={clearError} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default Error;
