import { Button, FormHelperText } from "@material-ui/core";
import React, { useState } from "react";
import { useController } from "react-hook-form";
import { createInfinityData } from "../../../../../../helpers/createInfinityData";
import { useQueryAdminUsers } from "../../../../../../queries/useQueryAdminUsers";
import auth from "../../../../../../store/modules/auth";
import Alert from "../../../../../System/Alert";
import CustomTextField from "../../../../../System/Components/CustomTextField";

const ToUser = ({ form, account }: any) => {
  const [params, setParams] = useState({
    page: 1,
    perPage: 20,
    search: "",
  });

  const { field } = useController({
    name: "toUser",
    control: form.control,
    rules: {
      required: true,
    },
  });

  const [open, setOpen] = useState(false);

  const queryResult = useQueryAdminUsers(params);

  let options = createInfinityData(queryResult.data?.pages || []);

  options = options.filter((u) => u.userId !== account.userId);

  const name: string[] = [];

  if (field?.value?.name) {
    name.push(field.value.name);
  }

  if (field?.value?.email) {
    name.push(field.value.email);
  }

  if (field?.value?.phoneNumber) {
    name.push(field.value.phoneNumber);
  }

  return (
    <>
      <div className="flex flex-col mb-2">
        {field?.value?.name && (
          <span className="text-lg">
            To user: <b>{name.join(", ")}</b>
          </span>
        )}

        {!!auth.profile && auth.profile.isAdmin && (
          <a
            className="font-bold text-md cursor-pointer"
            onClick={() => setOpen(true)}
          >
            {!field?.value?.name
              ? `Select the user to whom the funds will be transferred`
              : `Change`}
          </a>
        )}

        {form?.formState?.errors?.toUser?.type === "required" && (
          <FormHelperText error>Field is required</FormHelperText>
        )}
      </div>

      <Alert
        open={open}
        setOpen={setOpen}
        content={
          <div className="w-full">
            <h2 className="text-lg font-bold">User search</h2>

            <CustomTextField
              value={params.search}
              label="Search (name, email, phone)"
              fullWidth
              onChange={(e) =>
                setParams((ps) => ({ ...ps, search: e.target.value }))
              }
            />

            <div className="flex flex-col w-full mt-4 h-full max-h-[300px] overflow-y-auto">
              {options.map((o, i) => {
                const name: string[] = [];

                if (o.name) {
                  name.push(o.name);
                }

                if (o.email) {
                  name.push(o.email);
                }

                if (o.phoneNumber) {
                  name.push(o.phoneNumber);
                }

                return (
                  <div
                    key={o.userId}
                    onClick={() => {
                      field.onChange(o);
                      setOpen(false);
                    }}
                    className="py-2 px-2 cursor-pointer hover:bg-gray-100 border-b border-gray-200"
                  >
                    <span>
                      {i + 1}. {name.join(", ")}
                    </span>
                  </div>
                );
              })}

              {queryResult.hasNextPage && (
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => queryResult.fetchNextPage()}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};

export default ToUser;
