import { view } from "@risingstack/react-easy-state";
import { format } from "date-fns";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { formatDateToMMddyyyySeparatorSlash } from "../../../../../../helpers";
import ui from "../../../../../../store/modules/ui";
import MigomTable3 from "../../../../../System/MigomTable3";
import { makeDates } from "../../../AllClients/hooks/useFilter";
import CurrencyCell from "../components/CurrencyCell";
import FeatureBranchesFilter from "../components/FeatureBranchesFilter";
import FeatureIgnoreZeroValue from "../components/FeatureIgnoreZeroValue";
import FeatureLessThan from "../components/FeatureLessThan";
import FeatureMoreThan from "../components/FeatureMoreThan";
import FeatureRadioDataType from "../components/FeatureRadioDataType";
import FeatureSearchFilter from "../components/FeatureSearchFilter";
import FeatureSumBy from "../components/FeatureSumBy";
import FeatureTotalCurrency from "../components/FeatureTotalCurrency";
import TotalCell from "../components/TotalCell";
import { useAvailableCurrencies } from "../hooks/useAvailableCurrencies";
import { dateFiltersAtom } from "./atoms/dateFilters";
import { dateParamsAtom } from "./atoms/dateParams";
import FeatureDateFilter from "./components/FeatureDateFilter";
import { useQueryPNLDate } from "./queries/useQueryPNLDate";

export default view(() => {
  const history = useHistory();

  const [enabled, setEnabled] = useState(false);
  const [params, setParams] = useAtom(dateParamsAtom);
  const match = useRouteMatch<{ userId?: string }>();

  const tableQuery = useQueryPNLDate(params, enabled);

  const [filters, setFilters] = useAtom(dateFiltersAtom);

  const availableCurrencies = useAvailableCurrencies(
    tableQuery?.data?.data,
    filters
  );

  useEffect(() => {
    setParams((prevState) => ({
      ...prevState,
      branchId: match.params.userId,
    }));
  }, [setParams, match.params.userId]);

  const { dashboardPnlShowHidden } = ui;

  useEffect(() => {
    setParams((prevState) => {
      return {
        ...prevState,
        showHidden: dashboardPnlShowHidden,
      };
    });

    setEnabled(true);
  }, [setParams, dashboardPnlShowHidden]);

  const columns = useMemo(() => {
    return [
      {
        id: "date",
        label: "Date",
        nowrap: true,
        render: (rowData) => {
          const isTotal = rowData?.date?.includes("Total");
          return (
            <a
              className={isTotal ? "font-bold" : "font-normal"}
              onClick={() => {
                const { from, to } = makeDates({ dateType: filters.dateType });

                history.push("/app/admin/reports/pnl/full-details", {
                  branchId: params?.branchId,
                  from: isTotal
                    ? from
                    : formatDateToMMddyyyySeparatorSlash(rowData?.date),
                  to: isTotal
                    ? to
                    : formatDateToMMddyyyySeparatorSlash(rowData?.date),
                });
              }}
            >
              {!rowData?.date?.includes("Total") &&
                params.sumBy === "Week" &&
                format(new Date(rowData?.date), "MMM dd, yyyy")}

              {!rowData?.date?.includes("Total") &&
                params.sumBy === "Month" &&
                format(new Date(rowData?.date), "MMM, yyyy")}

              {!rowData?.date?.includes("Total") &&
                params.sumBy === "Quartal" &&
                format(new Date(rowData?.date), "QQQ, yyyy")}

              {!rowData?.date?.includes("Total") &&
                params.sumBy === "Year" &&
                format(new Date(rowData?.date), "yyyy")}

              {rowData?.date?.includes("Total") && rowData?.date}
            </a>
          );
        },
      },
      ...availableCurrencies.map((c) => ({
        id:
          filters.dataType === "+/-"
            ? c.value
            : c.value + "-" + filters.dataType,
        label: c.value,
        nowrap: true,
        right: true,
        render: (rowData) => {
          const isTotal = rowData?.date?.includes("Total");
          return (
            <a
              className={isTotal ? "font-bold" : "font-normal"}
              onClick={() => {
                const { from, to } = makeDates({ dateType: filters.dateType });

                history.push("/app/admin/reports/pnl/full-details", {
                  currencyId: c.value,
                  branchId: params?.branchId,
                  from: isTotal
                    ? from
                    : formatDateToMMddyyyySeparatorSlash(rowData?.date),
                  to: isTotal
                    ? to
                    : formatDateToMMddyyyySeparatorSlash(rowData?.date),
                });
              }}
            >
              <CurrencyCell
                rowTotalKey="date"
                rowData={rowData}
                c={c}
                filters={filters}
                params={params}
              />
            </a>
          );
        },
      })),
      {
        id:
          filters.dataType === "+/-"
            ? "total"
            : "total" + "-" + filters.dataType,
        label: "Total",
        nowrap: true,
        right: true,
        className: "bg-gray-100",
        render: (rowData) => {
          return (
            <a
              onClick={() => {
                const { from, to } = makeDates({ dateType: filters.dateType });
                const isTotal = rowData?.date?.includes("Total");

                history.push("/app/admin/reports/pnl/full-details", {
                  branchId: params?.branchId,
                  from: isTotal
                    ? from
                    : formatDateToMMddyyyySeparatorSlash(rowData?.date),
                  to: isTotal
                    ? to
                    : formatDateToMMddyyyySeparatorSlash(rowData?.date),
                });
              }}
            >
              <TotalCell rowData={rowData} params={params} filters={filters} />
            </a>
          );
        },
      },
    ];
  }, [availableCurrencies, filters, params, history]);

  return (
    <div className="w-full">
      <div className="flex flex-wrap flex-row items-end w-full flex-1 mb-2">
        <FeatureSearchFilter params={params} setParams={setParams} />
        <FeatureBranchesFilter params={params} setParams={setParams} />
        <FeatureDateFilter
          filters={filters}
          params={params}
          setParams={setParams}
          setFilters={setFilters}
        />

        {/*   {isDev && (
          <div className="mb-2">
            <CustomDateRange
              filters={filters}
              params={params}
              setParams={setParams}
              setFilters={setFilters}
            />
          </div>
        )}
 */}
        <FeatureRadioDataType
          filters={filters}
          setFilters={setFilters}
          setParams={setParams}
        />
        <FeatureTotalCurrency params={params} setParams={setParams} />
        <FeatureLessThan params={params} setParams={setParams} />
        <FeatureMoreThan params={params} setParams={setParams} />
        <FeatureSumBy params={params} setParams={setParams} />
        <FeatureIgnoreZeroValue params={params} setParams={setParams} />
      </div>

      <div className="max-w-sm">
        <MigomTable3
          columns={columns}
          rows={tableQuery?.data?.data}
          loading={tableQuery.isLoading || tableQuery.isFetching}
          defaultSort={{
            sortBy: params.sortBy,
            sortDirection: params.sortDirection,
          }}
          onSort={({ sortBy, sortDirection }) => {
            setParams((prevState) => ({
              ...prevState,
              sortBy,
              sortDirection,
            }));
          }}
          rowClassName={(row) => {
            const isTotalRow = row?.date?.includes(`Total`);

            if (isTotalRow) {
              return "bg-gray-100";
            }
          }}
        />
      </div>
    </div>
  );
});
