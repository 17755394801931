import { format } from "date-fns";
import React, { memo } from "react";
import { checkDate } from "../../../../../../../helpers/checkDate";
import CustomAutocompleteFetch from "../../../../../../System/Components/CustomAutocompleteFetch";
import CustomDate from "../../../../../../System/Components/CustomDate";
import ShowAndHide from "../../../../../../System/FormComponents/ShowAndHide";
import { makeDates } from "../../../../AllClients/hooks/useFilter";

export default memo(({ filters, params, setParams, setFilters }: any) => {
  return (
    <>
      <div className="max-w-[10rem] w-full !mr-4 !mb-2">
        <CustomAutocompleteFetch
          value={filters?.dateType}
          options={[
            "2020-Today",
            "Last 365 Days",
            "2023",
            "2022",
            "2021",
            "2020",
            "Custom Range",
          ]}
          label="Choose Period"
          onChange={(e, dateType) => {
            const { from, to } = makeDates({ dateType });

            setParams((prevState) => ({
              ...prevState,
              page: 1,
              from: from,
              to: to,
            }));

            setFilters((prevState) => ({ ...prevState, dateType }));
          }}
        />
      </div>

      <ShowAndHide show={filters?.dateType === "Custom Range"}>
        <div className="flex">
          <div className="max-w-[9rem]">
            <CustomDate
              label="From"
              value={params?.from || null}
              onChange={(value) => {
                if (checkDate(value)) {
                  setParams((prevState) => ({
                    ...prevState,
                    from: format(value, "MM/dd/yyyy"),
                    page: 1,
                  }));
                }
              }}
            />
          </div>

          <div className="max-w-[9rem]">
            <CustomDate
              label="To"
              value={params?.to || null}
              onChange={(value) => {
                if (checkDate(value)) {
                  setParams((prevState) => ({
                    ...prevState,
                    to: format(value, "MM/dd/yyyy"),
                    page: 1,
                  }));
                }
              }}
            />
          </div>
        </div>
      </ShowAndHide>
    </>
  );
});
