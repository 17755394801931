import { InputLabel, TextField } from "@material-ui/core";
import { clsx } from "clsx";
import en from "date-fns/locale/en-GB"; // the locale you want
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useController } from "react-hook-form";
registerLocale("en-GB", en);

const MyDate = ({
  control,
  name,
  label,
  helperText,
  rules,
  disabled,
  width,
  id,
  showTime = false,
}: any) => {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  const { onChange, onBlur, value, ref } = field;

  return (
    <div className={clsx(" relative", width ? width : "")}>
      {label && (
        <InputLabel shrink className="flex -mb-[9px]">
          {label}
        </InputLabel>
      )}

      <DatePicker
        id={id}
        customInput={<TextField inputRef={ref} />}
        name={name}
        selected={value}
        locale="en-GB"
        showTimeSelect={showTime}
        timeFormat="p"
        timeIntervals={15}
        // dateFormat="Pp"
        dateFormat={showTime ? "MM/dd/yyyy p" : "MM/dd/yyyy"}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        className="w-full"
        portalId="app"
        // className="!border-b-2 !max-w-full !text-[#282828] !text-[1rem] !py-[.2rem] !border-gray-200 !outline-none focus:!border-main"
        popperClassName="!z-[9999]"
      />

      {helperText && <span className="text-[.75rem]">{helperText}</span>}
    </div>
  );
};

export default MyDate;
