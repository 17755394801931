import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import { useController } from "react-hook-form";
import NumberFormat from "react-number-format";
import ErrorMessage from "./ErrorMessage";

export default ({
  autoFocus,
  control,
  name,
  rules,
  disabled,
  label,
  onFocus,
  hideBorder,
  required,
  endAdornment,
}: any) => {
  const controller = useController({
    name: name,
    control: control,
    rules: {
      required: required ? `Field '${label}' is required` : false,
      ...(rules || {}),
    },
  });

  return (
    <>
      <NumberFormat
        type="text"
        onChange={controller?.field.onChange}
        onBlur={controller?.field.onBlur}
        inputRef={controller?.field.ref}
        name={controller?.field.name}
        value={controller?.field.value || ""}
        autoFocus={autoFocus}
        onFocus={onFocus}
        customInput={TextField}
        allowNegative={false}
        thousandSeparator
        InputProps={{
          endAdornment: (
            <>
              {endAdornment && (
                <InputAdornment position="start">
                  <b className="text-lg">{endAdornment}</b>
                </InputAdornment>
              )}
            </>
          ),
          disableUnderline: hideBorder,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        disabled={disabled}
        label={label}
        required={required}
      />

      <ErrorMessage controller={controller} />
    </>
  );
};
