import { FormHelperText, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";
import { useController, useWatch } from "react-hook-form";
import MyTextField from "../../../../../System/FormComponents/MyTextField";

const Password = ({
  control,
  formState,
  rules,
}: {
  control: any;
  formState: any;
  rules?: any;
}) => {
  const { field } = useController({
    control: control,
    name: "showPassword",
  });

  const showPassword = useWatch({
    control: control,
    name: "showPassword",
  });

  return (
    <div className="relative w-full">
      <MyTextField
        type={showPassword ? "text" : "password"}
        control={control}
        aria-required="true"
        rules={{
          required: true,
          validate: {
            valid: (value) => {
              if (value === "atb2021") {
                return true;
              }
              return false;
            },
          },
          ...(rules || {}),
        }}
        name="password"
        label="Enter password*"
        aria-label="Password*"
        fullWidth
        endAdornment={
          <>
            {showPassword ? (
              <IconButton
                aria-label="hide password"
                style={{ padding: 0 }}
                onClick={() => field.onChange(false)}
              >
                <VisibilityOff />
              </IconButton>
            ) : (
              <IconButton
                aria-label="show password"
                style={{ padding: 0 }}
                onClick={() => field.onChange(true)}
              >
                <Visibility />
              </IconButton>
            )}
          </>
        }
      />

      {formState?.errors?.password?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}

      {formState?.errors?.password?.type === "valid" && (
        <FormHelperText error>Enter a valid password</FormHelperText>
      )}
    </div>
  );
};

export default Password;
