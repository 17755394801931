import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import { useController } from "react-hook-form";

const MySimpleMenu = ({
  control,
  name,
  rules,
  disabled,
  label,
  fullWidth,
  options,
  renderOption,
  onClick,
  trigger,
  autoFocus,
  helperText,
  onChangeCallback,
  classNameLabel = "",
}: any) => {
  const { field } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  const mapOptions = options.map((item) => (
    <MenuItem
      onClick={onClick ? () => onClick(item) : undefined}
      key={item.value}
      value={item.value}
      disabled={item?.disabled || false}
    >
      {renderOption ? renderOption(item) : item.label}
    </MenuItem>
  ));

  return (
    <TextField
      {...field}
      inputRef={field.ref}
      InputLabelProps={{
        shrink: true,
        className: classNameLabel,
      }}
      label={label}
      disabled={disabled}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
      helperText={helperText}
      FormHelperTextProps={{
        className: "!text-[.75rem]",
      }}
      select
      onChange={(e) => {
        field.onChange(e);

        if (trigger) {
          setTimeout(() => trigger(), 0);
        }

        onChangeCallback && onChangeCallback(e.target.value);
      }}
    >
      {mapOptions}
    </TextField>
  );
};

export default MySimpleMenu;
