import { Button } from "@material-ui/core";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = () => {
  return (
    <div className="flex justify-center items-center flex-col p-8 flex-1">
      <p className="text-2xl font-bold max-w-4xl text-center mb-4">
        Please reload the page to enable the newest update to our website
        designed to further improve your client experience with Migom Bank!
      </p>

      <Button
        variant="contained"
        color="primary"
        onClick={() => window.location.reload()}
      >
        Refresh
      </Button>
    </div>
  );
};

const ErrorBound = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
};

export default ErrorBound;
