import { view } from "@risingstack/react-easy-state";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ITask from "../../../../../interfaces/task";
import accounts from "../../../../../store/modules/accounts";
import auth from "../../../../../store/modules/auth";
import ui from "../../../../../store/modules/ui";
import { en } from "../../../../../translate/en";
import IStepProps, {
  IFormClientDeposit,
  IPayloadForConfirm,
} from "./interfaces/stepProps";

const Deposit = view(
  ({
    setOpen,

    fetch,
    task,
  }: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fetch?: () => void;
    task?: ITask;
  }) => {
    const { account } = accounts;
    const [step, setStep] = useState<number>(1);
    const [data, setData] = useState<Record<string, any>>();
    const [success, setSuccess] = useState<boolean>(false);
    const [payloadForConfirm, setPayloadForConfirm] = useState<
      IPayloadForConfirm | undefined
    >();
    const [canSepa, setCanSepa] = useState<boolean>(false);

    let body;

    try {
      body = task?.body ? JSON.parse(task?.body) : {};
    } catch {
      body = task?.body;
    }

    const form = useForm<IFormClientDeposit>({
      defaultValues: {
        nameOnAccount: body?.NameOnAccount || "",
        email: body?.Email || (!!auth.profile && auth.profile?.email) || "",
        swiftOrSepa: "SWIFT",
        accountNumber: body?.AccountNumber || "",
        nameOfBank: task?.memo || "",
        amount: task?.amount || "",
        currencyId: task?.currencyId || "",
      } as IFormClientDeposit,
      mode: "onChange",
    });

    const stepProps: IStepProps = {
      step,
      setStep,
      data,
      setData,
      payloadForConfirm,
      setPayloadForConfirm,
      setOpen,
      account,
      success,
      setSuccess,
      form,
      setCanSepa,
      canSepa,
      fetch,
      formState: form.formState,
    };

    useEffect(() => {
      ui.textLoading = en.deposit.finish.loading;
    }, []);

    const { setValue } = form;

    useEffect(() => {
      setTimeout(
        () =>
          setValue("currencyId", task?.currencyId || account?.currencyId || ""),
        0
      );
    }, [account?.currencyId, setValue, task?.currencyId]);

    useEffect(() => {
      setTimeout(() => setValue("nameOfBank", task?.memo || ""), 0);
    }, [setValue, task?.memo]);

    useEffect(() => {
      setTimeout(
        () =>
          setValue(
            "email",
            body?.Email || (!!auth.profile && auth.profile?.email) || ""
          ),
        0
      );
    }, [body?.Email, setValue]);

    useEffect(() => {
      setTimeout(() => setValue("accountNumber", body?.AccountNumber || ""), 0);
    }, [body?.AccountNumber, setValue]);

    useEffect(() => {
      setTimeout(() => setValue("nameOnAccount", body?.NameOnAccount || ""), 0);
    }, [body?.NameOnAccount, setValue]);

    useEffect(() => {
      setTimeout(
        () => setValue("swiftOrSepa", body?.SwiftOrSepa || "SWIFT"),
        0
      );
    }, [body?.SwiftOrSepa, setValue]);

    useEffect(() => {
      setTimeout(() => setValue("amount", task?.amount || ""), 0);
    }, [setValue, task?.amount]);

    return (
      <>
        <h1 className="text-center text-gray-600 text-2xl">
          Notification Of Deposit {account?.currencyId}, account
          {account?.shortNumber}
        </h1>
        {/* <CheckIban account={account} {...form} setCanSepa={setCanSepa} />

        {step === 1 && <Step1 {...stepProps} />}
        {step === 2 && <Step2 {...stepProps} />} */}
        <h2 className="text-center font-bold text-2xl">
          Migom Bank is currently in administration. No deposits will be
          accepted until further notice.
        </h2>
      </>
    );
  }
);

export default Deposit;
