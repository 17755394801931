import numbro from "numbro";
import React from "react";
import {
  formatDateToMMddyyyyHHmm,
  formatToCurrencyString,
} from "../../../../../../helpers";
import { BuyPrice } from "../../../../../System";
import ShortAddress from "../../../../../System/ShortAddress";
import Box from "../components/Box";
import FieldBox from "../components/FieldBox";
import { getColorForState } from "../tools/getColorForState";

const CryptoWithdraw = ({ task }) => {
  const colorState = getColorForState(task);

  let risk;

  if (task?.fromAccount?.ownerRiskLevelId === "Green") {
    risk = <span className="text-green-500 font-bold">Green</span>;
  }

  if (task?.fromAccount?.ownerRiskLevelId === "Red") {
    risk = <span className="text-red-600 font-bold">Red</span>;
  }

  if (task?.fromAccount?.ownerRiskLevelId === "Orange") {
    risk = <span className="text-orange-600 font-bold">Orange</span>;
  }

  if (task?.fromAccount?.ownerRiskLevelId === "Yellow") {
    risk = <span className="text-yellow-600 font-bold">Yellow</span>;
  }

  if (task?.fromAccount?.ownerRiskLevelId === "Blue") {
    risk = <span className="text-main font-bold">Blue</span>;
  }

  return (
    <>
      <Box header="">
        <FieldBox
          label="Task state"
          value={task?.taskStateName}
          style={{ color: colorState }}
        />

        <>
          {task?.taskStateId === "Rejected" && (
            <FieldBox label="Reason" value={task?.internalSubject} />
          )}
        </>

        <FieldBox label="Task ID" value={task?.taskId} showCopy />

        <FieldBox
          label="Currency:"
          value={
            <div className="flex">
              <img
                src={`/currencies/${task.currencyId?.toLowerCase()}.svg`}
                className="w-6 min-w-6 mr-2"
              />
              <span>{task?.currencyId}</span>
            </div>
          }
        />

        <FieldBox
          label="Amount:"
          value={formatToCurrencyString(
            numbro.unformat(task?.amount || 0),
            task?.currencyId
          )}
        />

        <FieldBox
          label="Amount in USD:"
          value={
            <BuyPrice
              amount={task?.amount || 0}
              pair={`${task?.currencyId}-USD`}
            />
          }
        />

        <FieldBox
          label="Date:"
          value={formatDateToMMddyyyyHHmm(task?.ts)}
          className="font-normal"
        />

        <div className="px-2 py-2 border-2 border-gray-200 rounded-lg mb-2">
          <h2 className="mb-4 text-lg">From</h2>

          <FieldBox
            label="Name:"
            value={task?.firstUser?.name}
            className="font-normal"
          />

          <FieldBox
            label="From:"
            value={
              task?.fromAccount?.number.length > 11 ? (
                <ShortAddress value={task?.fromAccount?.number} size={5} />
              ) : (
                task?.fromAccount?.number
              )
            }
            className="font-normal"
            showCopy
          />

          <FieldBox label="Risk:" value={risk} className="font-normal" />
        </div>

        <div className="px-2 py-2 border-2 border-gray-200 rounded-lg mb-2">
          <h2 className="mb-4 text-lg">To</h2>

          {task?.toCryptoRecipient?.name && (
            <FieldBox
              label="Recipient's Name:"
              value={task?.toCryptoRecipient?.name}
              className="font-normal"
              showCopy
            />
          )}

          <FieldBox
            label="Wallet:"
            copyValue={
              task?.toCryptoRecipient?.address || task?.toWalletAddress
            } // fix copy
            value={
              task?.toCryptoRecipient?.address?.length > 11 ||
              task?.toWalletAddress?.length > 11 ? (
                <ShortAddress
                  value={
                    task?.toCryptoRecipient?.address || task?.toWalletAddress
                  }
                  size={5}
                />
              ) : (
                task?.toCryptoRecipient?.address || task?.toWalletAddress
              )
            }
            className="font-normal"
            showCopy
          />

          {!task?.toCryptoRecipient?.address && (
            <p className="text-lg text-red-600 font-bold">
              This transfer has no recipient! The recipient has been deleted.
              You must &apos;Reject&apos; this transfer.
            </p>
          )}
        </div>

        <FieldBox label="Memo" value={task?.memo} className="font-normal" />
      </Box>
    </>
  );
};

export default CryptoWithdraw;
