import { TextField } from "@material-ui/core";
import React from "react";
import { useController } from "react-hook-form";
import CardInput from "./CardInput";

const MyCardTextField = ({
  autoFocus,
  control,
  name,
  rules,
  disabled,
  label,
  prefix = "",
  onFocus,
}: any) => {
  const { field } = useController({
    name: prefix + name,
    control: control,
    rules: rules,
  });

  return (
    <TextField
      {...field}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e);
        }
      }}
      autoFocus={autoFocus}
      type="text"
      autoComplete="off"
      InputProps={{
        inputComponent: CardInput,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      disabled={disabled}
      label={label}
    />
  );
};

export default MyCardTextField;
