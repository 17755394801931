import { DialogActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { view } from "@risingstack/react-easy-state";
import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { checkIsCryptoCurrency } from "../../../helpers/formatToCurrencyString";
import accounts from "../../../store/modules/accounts";
import auth from "../../../store/modules/auth";
import { lastOperationsAtom } from "../../../store/modules/lastOperations";
import GreenButton from "../../Roles/Admin/SmartForms/components/GreenButton";
import CryptoDeposit from "../../Roles/Client/Overview/AccountsBlocks/Account/Crypto/CryptoDeposit";
import Deposit from "../../Roles/Client/Pay/Deposit";
import { Alert } from "../../System";
import LazyImage from "../../System/LazyImage";

const useStyles = makeStyles({
  root: {
    "@global": {
      h2: {
        fontWeight: 700,
        textAlign: "center",
        margin: 0,
      },
    },
  },
});

const Welcome = view(() => {
  const classes = useStyles();
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openCryptoDeposit, setOpenCryptoDeposit] = useState(false);

  const [itemsLastOperations] = useAtom(lastOperationsAtom);

  const { items } = accounts;
  const { roles, isClient, isCorporate, onBoardStatus } = auth?.profile || {};

  const [open, setOpen] = useState(false);

  const ref = useRef<boolean>(false);

  /* test */

  useEffect(() => {
    if (
      onBoardStatus === "Completed" &&
      items?.length > 0 &&
      itemsLastOperations?.isFetching &&
      itemsLastOperations?.data?.length === 0 &&
      roles &&
      roles?.length > 0 &&
      (isClient || isCorporate)
    ) {
      const showDialog = items?.every((account) => {
        if (account?.availableBalance <= 0) {
          return true;
        }

        return false;
      });

      if (!ref.current && showDialog) {
        ref.current = true;
        setOpen(true);
      }
    }
  }, [
    isClient,
    isCorporate,
    items,
    itemsLastOperations?.data?.length,
    itemsLastOperations?.isFetching,
    onBoardStatus,
    roles,
  ]);

  const accountsList = items.map((item) => (
    <a
      key={item.accountId}
      className="mb-2.5"
      onClick={() => {
        accounts.account = { ...item };

        if (checkIsCryptoCurrency(item?.currencyId)) {
          setOpenCryptoDeposit(true);
        } else {
          setOpenDeposit(true);
        }

        setOpen(false);
      }}
    >
      {item.currencyId} account: <b>{item.number}</b>
    </a>
  ));

  return (
    <>
      <Alert
        open={open}
        setOpen={setOpen}
        content={
          <div className={classes.root}>
            <div className="flex justify-center">
              <div className="max-w-xs">
                <LazyImage
                  className="max-w-full w-full"
                  src="/images/design/landing/pictures/safe_money.png"
                />
              </div>
            </div>

            <div className="mb-4">
              <h2 className="block text-xl">Welcome to Migom Bank!</h2>
            </div>

            <p className="mb-2 text-sm">
              Please select the currency account for your first deposit:
            </p>

            <div className="flex flex-col">{accountsList}</div>

            <DialogActions>
              <GreenButton label="Later" onClick={() => setOpen(false)} />
            </DialogActions>
          </div>
        }
      />

      {openDeposit && (
        <Alert
          open={openDeposit}
          setOpen={setOpenDeposit}
          content={<Deposit setOpen={setOpenDeposit} />}
          size="sm"
        />
      )}

      {openCryptoDeposit && (
        <CryptoDeposit
          open={openCryptoDeposit}
          setOpen={setOpenCryptoDeposit}
          account={accounts.account}
        />
      )}
    </>
  );
});

export default Welcome;
